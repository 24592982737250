import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Privacy = ({ location }) => {
  return (
    <Layout>
      <SEO
        title={`プライバシーポリシー`}
        description={`プライバシーポリシーの明記ページ`}
        location={location}
      />
      <h2 className="my-4 border-bottom border-info">個人情報の利用目的</h2>
      <p>
        当ブログでは、メールでのお問い合わせ、メールマガジンへの登録などの際に、名前（ハンドルネーム）、メールアドレス等の個人情報をご登録いただく場合がございます。
        <br />
        これらの個人情報は質問に対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
      </p>
      <h2 className="my-4 border-bottom border-info">
        個人情報の第三者への開示
      </h2>
      <p>
        当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
        <br />
        ・本人のご了解がある場合 ・法令等への協力のため、開示が必要となる場合
        <br />
        個人情報の開示、訂正、追加、削除、利用停止
        <br />
        ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
        <br />
      </p>
      <h2 className="my-4 border-bottom border-info">
        アクセス解析ツールについて
      </h2>
      <p>
        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
        このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくはここをクリックしてください。
      </p>
      <h2 className="my-4 border-bottom border-info">広告の配信について</h2>
      <p>
        当ブログは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。
        <br />
        第三者がコンテンツおよび宣伝を提供し、訪問者から直接情報を収集し、訪問者のブラウザにクッキーを設定したりこれを認識したりする場合があります。
        <br />
      </p>
      <h2 className="my-4 border-bottom border-info">免責事項</h2>
      <p>
        当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
        <br />
        当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。
        <br />
        当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
        <br />
      </p>
      <h2 className="my-4 border-bottom border-info">
        プライバシーポリシーの変更について
      </h2>
      <p>
        当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
        <br />
        修正された最新のプライバシーポリシーは常に本ページにて開示されます。
        <br />
      </p>
    </Layout>
  )
}

export default Privacy
